import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'gatsby'
import { Row, Col } from 'styled-bootstrap-grid'
import cn from 'classnames'

import { HolidaySetBanner } from 'components/common'
import { fetchBlogArticles } from 'components/blog/actions'
import { withMainLayout } from 'components/layouts'
import { BlogLayout, BlogCard } from 'components/blog'
import SEO from 'components/seo'

import css from './style.module.scss'
import imgBg1920 from './img/bg1920.jpg'
import imgBg1440 from './img/bg1440.jpg'
import imgBg768 from './img/bg768.jpg'
import imgBg320 from './img/bg320.jpg'

const backUrl = {to: '/blog-history', name: 'Вся история'}
const pageData = {title: 'История', slug: 'history'}

function BlogLawsPage({
  fetchBlogArticles,
}) {
  const [stateArticles, setArticles] = useState([])

  useEffect(() => {
    fetchBlogArticles(`/section/${pageData.slug}/articles/`)
      .then(result => {
        setArticles(result.results)
      })
  }, [])

  return (
    <React.Fragment>
      <SEO
        title={pageData.title}
      />
      <BlogLayout
        images={{
          bg1920: imgBg1920,
          bg1440: imgBg1440,
          bg768: imgBg768,
          bg320: imgBg320,
        }}
        title={pageData.title}
      >
        <div className={cn('container', css.blogPage)}>
          <Row>
            {
              stateArticles.map((item, index) => (
                <Col
                  col={12}
                  md={6}
                  xl={4}
                  key={index}
                >
                  <Link to={`${backUrl.to}/${item.id}`}>
                    <BlogCard
                      title={item.name}
                      text={item.short_text}
                      className={css.card}
                    />
                  </Link>
                </Col>
              ))
            }
          </Row>
        </div>
      </BlogLayout>
      <HolidaySetBanner/>
    </React.Fragment>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchBlogArticles: bindActionCreators(fetchBlogArticles, dispatch),
  })
)(withMainLayout(BlogLawsPage))
export { backUrl }